import React, { Component } from 'react';

import Swal from 'sweetalert2'
const creationMessage="Sucessfully Created!";
const uploadMessage="Sucessfully Uploaded!";

const updationMessage="Sucessfully updated";
const deletionMessage="Sucessfully Removed";
const errorMessage="Error to proceed!"
const deleteTittleMessage="Are you sure to remove this ";
const deleteWarningMessage="You won't be able to revert this!";
const deleteConfirmationMessage='Yes, delete it!';

const alertCreation = (itemName) => {
    itemName=(typeof(itemName) === 'undefined') ?'Item':itemName;
    Swal.fire(creationMessage, "added new "+itemName, "success");
}
const closingPopup=(item)=>{
     
Swal.fire({
    title: '',
    text:item.popupMessage,
    imageUrl: '/img/closingvessels.png',
    allowOutsideClick: false,
    showCancelButton: false,
    showConfirmButton: false,
    buttons:false,
    imageWidth: 700,
    imageHeight: 300,
    imageAlt: 'Custom image',
  })
}
const alertUpload = (itemName) => {
    itemName=(typeof(itemName) === 'undefined') ?'Item':itemName;
    Swal.fire(uploadMessage, "Uploaded "+itemName+" data file Sucessfully", "success");
}
const alertError= (message=errorMessage) => {
    Swal.fire(message, "Try again","error");
    }

    
const alertUpdation= (itemName) => {
    itemName=(typeof(itemName) === 'undefined') ?'Item':itemName;
    Swal.fire(updationMessage, "updated "+itemName,"sucess");
    }
const alertDeletion = (itemName)  => {
    itemName=(typeof(itemName) === 'undefined') ?'Item':itemName;
    Swal.fire(deletionMessage, "Removed "+itemName,"sucess");
    }

    const confirmDeleteAction=(itemName)=>
    {
        itemName=(typeof(itemName) === 'undefined') ?'Item':itemName;
        let titleText=deleteTittleMessage+ itemName;            
        return confirmAction(titleText,deleteWarningMessage,deleteConfirmationMessage);                
        
        
        
    }

const confirmAction=(titleText,warningText,confirmButtonMessage)=>
{
    return Swal.fire({
        title: titleText,
        text: warningText,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonMessage
      });
    }
export  {alertCreation,alertUpload,closingPopup,alertError,alertUpdation,alertDeletion,confirmAction,confirmDeleteAction}

