import React, { useState, useEffect } from "react";
import { Link, Redirect, NavLink } from "react-router-dom";
import { baseURL } from "../helpers/globalConstants";
import {
	Row,
	Col,
	Container,
	Form,
	InputGroup,
	Button,
	Tab,
	Nav,
	Image,
	Badge,
	Modal,
	Card,
	Alert,
} from "react-bootstrap";
import GalleryCarousel from "./common/GalleryCarousel";
import CheckoutItem from "./common/CheckoutItem";
import QuickBite from "./common/QuickBite";
import Icofont from "react-icofont";
import CategoriesCarousel from "./common/CategoriesCarousel";
import ViewMenuDiscriptionModel from "./modals/ViewMenuDiscriptionModal";
import DataService from "../services/DataService";
import RestaurantTab from "./restaurant_info";
import SummaryCard from "../components/common/SummaryCard";
import Moment from "moment";

import { HashLink as HashLink } from "react-router-hash-link";
import {
	toCurrentTime,
	isTimeBetween,
	toTime24,
	getCurrentSlot,
	toFormattedDate24,
	dayArrayToDic,
	isInBetween,
	getCurrentDate,
	getRestaurantTimeSlot,
	getDistance,
} from "../services/DataFormatHandler";
import DeliveryModal from "./modals/DeliveryModalc";
import { closingPopup, confirmAction } from "./Alerts";
import Seo from "./seo";
import { getCartOfferList, getModifiedOfferItems } from "../helpers/helpers";
import BundleItemSelectionModal from "./modals/BundleItemSelectionModal";
import OfferEngine, { resetCart } from "../services/OfferEngine";

// 7start

function createCategoryMap(selectedCategories) {
	// Create a map for quick lookup of category IDs by food item ID
	const categoryMap = new Map();

	// Populate the map with foodItem.id as key and category.id as value
	try {
		selectedCategories.forEach((category) => {
			category.foods_displayed_in_menu.forEach((foodItem) => {
				categoryMap.set(foodItem.id, category.id);
			});
		});
	} catch (e) {
		return new Map();
	}

	return categoryMap;
}

const attachCategoryIdToCartItems = (categoryMap, cartItems) => {
	// Map through the original foodItems array to attach category_id
	return cartItems.map((item) => {
		const categoryId = categoryMap.get(item.id);
		if (categoryId) {
			return {
				...item,
				category_id: categoryId,
			};
		}
		return item;
	});
};

// 7start
const jsonParse = (string) => {
	if (typeof string !== "string") {
		return null;
	}
	try {
		return JSON.parse(string);
	} catch (error) {
		return null;
	}
};

function filterOutOfferRules(offerRules, selectedCategories, modeString) {
	let validOfferRules = [];
	if (offerRules?.length && modeString) {
		let availableCategories = selectedCategories.filter((catObj) => {
			let hasFoodItems = true;
			try {
				hasFoodItems = catObj.foods_displayed_in_menu.some((foodObj) => {
					return foodObj[modeString] === true;
				});
			} catch (error) {
				console.error("Error checking food items:", error);
				hasFoodItems = false;
			}
			return hasFoodItems;
		});

		offerRules.forEach((offerRule) => {
			const destination = offerRule.destinations[0];
			let isValid = false;

			if (destination.destination_type === "category") {
				isValid = destination.destination_categories.some((destCatId) => {
					return availableCategories.some(
						(catObj) => catObj.id === destCatId
					);
				});
			} else if (destination.destination_type === "item") {
				isValid = destination.destination_items.some((destItemId) => {
					return availableCategories.some((catObj) => {
						return catObj.foods_displayed_in_menu.some((foodObj) => {
							return foodObj.id === destItemId;
						});
					});
				});
			}

			if (isValid) {
				validOfferRules.push(offerRule);
			}
		});
	} else {
		validOfferRules = offerRules;
	}
	return validOfferRules;
}
// 7end
class Detail extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.dataHandler = new DataService("restaurants");
		//fetch all datas from api

		//set key path
		this.dataHandler.setPath(this.props.pathName);
		this.deliveryModes = ["is_delivery_enabled", "is_takeout_enabled"];
		this.orderString = ["Delivery Order", "Takeout Order"];
		this.profile = {};
		this.state = {
			//get cart values from local store, return an empty array if no argument
			del_mode: 0,
			emptyItem: false,
			cart: this.dataHandler.getCart(this.props.pathName),
			rest_location: {},
			showFuture: false,
			catview: true,
			activeCategory: 0,
			gallerylength: 5,
			address: this.dataHandler.getShopingAddress(this.props.pathName),
			shopping_time: this.dataHandler.getShopingTime(this.props.pathName),
			shopping_date: getCurrentDate("YYYY-MM-DD"),
			cartCount: 0,
			showAddressModal: false,
			addressEror: false,
			showMenuProfile: false,
			max: 50,
			key_name: this.props.pathName,
			min: 1,
			menu: [],
			closeStatus: false,
			day: "",
			profile: {},
			selectedCategories: [],
			categoryFoodItemMap: new Map(),
			searchText: "",
			categories: [],
			offerRules: [],
			currentlyTakeoutAvailable: true,
			currentlyDeliveryAvailable: true,
			paymentTerms: {
				taxEnabled: false,
				deliveryFree: 0,
				openingStatus: true,
				deliveryCharge: 0,
				panelClass: "",
				panelCategoryClass: "",
			},
			modeOfDelevery: this.dataHandler.getDeleveryMode(this.props.pathName),

			modeChooseEnabled: !this.dataHandler.isDeleveryModeEnabled(
				this.props.pathName
			),
			offers: this.dataHandler.getOffers(this.props.pathName),
			rawOffers: [],
		};
		this.disableEmptyItem = this.disableEmptyItem.bind(this);
		this.changeDeliveryMode = this.changeDeliveryMode.bind(this);
		this.setShoppingAddress = this.setShoppingAddress.bind(this);
		this.setShoppingDate = this.setShoppingDate.bind(this);
		this.setShoppingTime = this.setShoppingTime.bind(this);
		this.setCurrentDeliveryMode = this.setCurrentDeliveryMode.bind(this);
		this.reloadData();
		this.incrementItem.bind(this);
		this.decreaseItem.bind(this);
		this.setDeleveryMode = this.setDeleveryMode.bind(this);
		this.setCustomaize = this.setCustomaize.bind(this);
		this.applyRuleBasedOffers = this.applyRuleBasedOffers.bind(this);
	}

	applyRuleBasedOffers = () => {
		let cartCopy = [...this.state.cart];
		console.log("cart_copy", cartCopy);
		try {
			const offerRules = this.state.offerRules;

			if (cartCopy?.length) {
				cartCopy = attachCategoryIdToCartItems(
					this.state.categoryFoodItemMap,
					cartCopy
				);
				const offerEngine = new OfferEngine(offerRules, [
					...this.state.cart,
				]);
				cartCopy = offerEngine.applyOffers(cartCopy);
				this.setState({ offerRules: offerEngine.getUpdatedOfferRules() });

				this.dataHandler.setOfferRules(
					this.state.key_name,
					offerEngine.getUpdatedOfferRules()
				);
			}
		} catch (e) {
			cartCopy = resetCart([...this.state.cart]);
		}
		console.log("updated cart", cartCopy);
		this.setState({ cart: cartCopy });
		this.dataHandler.setCart(this.state.key_name, cartCopy);
	};
	//
	setShoppingTime = (selecttime) => {
		this.setState({ time: selecttime });
	};

	setShoppingDate = (date) => {
		let date_value = Moment(date).format("YYYY-MM-DD");

		this.setState({ shopping_date: date_value });
	};
	setCurrentDeliveryMode = (e) => {
		let daySlots = getCurrentSlot();
		console.log("Slots current", daySlots);
		this.setState({ time: daySlots["time"] });
		this.setState({ shopping_date: daySlots["day"] }, () => {
			this.setDeleveryMode(daySlots["time"], true);
		});
	};
	setFuture = () => {
		this.setState({ showFuture: true });
	};
	getDeliveryFree = () => {
		let balance = 0;
		if (this.state.modeOfDelevery == 1) {
			return balance;
		}
		let TotalAmount = this.getCartSubTotal() - this.getDiscountTotal();
		if (TotalAmount > 0) {
			balance = this.state.paymentTerms["deliveryFree"] - TotalAmount;
			balance = balance > 0 ? Number(balance).toFixed(2) : 0;
		}

		return balance;
	};
	getTakeoutDiscountPrecentage = () => {
		if (this.state.modeOfDelevery == 0) {
			return 0;
		}
		if (this.state.profile.enable_takeout_discount == true) {
			var blnce = this.getTakeoutDiscount();

			if (blnce == 0) {
				let TotalAmount =
					this.getCartSubTotal() - this.getDiscountTotal(true);
				console.log("Discount Total amount", TotalAmount);
				console.log("Discount %", this.state.profile.take_out_discount);

				console.log("Discount Total amount", TotalAmount);

				var discount = Number(
					(TotalAmount * Number(this.state.profile.take_out_discount)) /
						100
				).toFixed(2);
				return discount;
			}
		}

		return 0;
	};
	getTakeoutDiscount = () => {
		let balance = 0;
		if (this.state.modeOfDelevery == 0) {
			return balance;
		}
		let TotalAmount = this.getCartSubTotal() - this.getDiscountTotal(true);
		if (TotalAmount > 0) {
			balance =
				this.state.profile.take_out_discount_minim_value - TotalAmount;
			balance = balance > 0 ? Number(balance).toFixed(2) : 0;
		}
		return balance;
	};

	changeDeliveryMode(mode) {
		//delivery
		if (mode == 1) {
			this.setState({ addressEror: false });
		}
		this.setState({ del_mode: mode }, () => {
			window.scroll();
		});
	}

	setToday = () => {};
	// Helper function to get the specific component from the address_components array
	getComponent = (components, type) => {
		const component = components.find((component) =>
			component.types.includes(type)
		);
		return component ? component.long_name : "";
	};
	setShoppingAddress = (addressObject) => {
		let inputParms = {};
		inputParms["address"] = addressObject.formatted_address;
		const addressComponents = addressObject.address_components;
		const addressComp = {
			street_address:
				this.getComponent(addressComponents, "street_number") +
				" " +
				this.getComponent(addressComponents, "route"),
			sub_premise: this.getComponent(addressComponents, "subpremise"),
			city: this.getComponent(addressComponents, "locality"),
			province: this.getComponent(
				addressComponents,
				"administrative_area_level_1"
			),
			postal_code: this.getComponent(addressComponents, "postal_code"),
			country: this.getComponent(addressComponents, "country"),
		};
		inputParms["address_components"] = addressComp;
		console.log("Parsed Address Components:", addressComp);

		if (addressObject.geometry) {
			let lat = addressObject.geometry.location.lat();
			let lng = addressObject.geometry.location.lng();
			inputParms["lat"] = lat;
			inputParms["lng"] = lng;
			let distance = getDistance(inputParms, this.state.rest_location);
			this.dataHandler.setDistance(this.state.key_name, distance);
			console.log("distance", distance);

			// 7start
			if (this.state.profile.enable_draw_delivery_zone) {
				let delivery_zones = this.state.profile.delivery_zones;
				let locationToCheck = new window.google.maps.LatLng(lat, lng);
				let polygonPath = null;
				let polygonShape = null;
				let checkResult = false;

				for (let index = 0; index < delivery_zones.length; index++) {
					const zone = delivery_zones[index];
					if (zone.is_enabled === true) {
						polygonPath = jsonParse(zone.coordinates);
						if (polygonPath && polygonPath.length > 2) {
							polygonShape = new window.google.maps.Polygon({
								paths: polygonPath,
							});
							checkResult =
								window.google.maps.geometry.poly.containsLocation(
									locationToCheck,
									polygonShape
								);
							if (checkResult) {
								this.dataHandler.setZone(this.state.key_name, zone);
								break;
							}
						}
					}
				}

				if (!checkResult) {
					this.setState({ addressEror: true });
					this.setState({ address: inputParms });
				} else {
					this.setState({ addressEror: false });
					this.setState({ address: inputParms });
				}
			} else {
				if (distance > this.state.profile.maxim_delevery) {
					this.setState({ addressEror: true });
					this.setState({ address: inputParms });
				} else {
					this.setState({ addressEror: false });
					this.setState({ address: inputParms });
				}
			}
			// 7end
		}
	};
	searchFormHandler = (event) => {
		let key = "";
		let value = "";

		let searchText = { ...this.state.searchText };

		if (event.target.type == "text") {
			key = event.target.name;
			value = event.target.value;
		}

		this.setState({ searchText: value });
	};

	disableDeleveryMode = () => {
		this.setState({ modeChooseEnabled: true });
	};
	enableModeChoosePopup = (choice = true) => {
		this.setState({ modeChooseEnabled: choice });
	};

	reloadCartByMode() {
		let modeString = this.deliveryModes[this.state.modeOfDelevery];

		let inputCart = [...this.state.cart];
		inputCart = inputCart.filter((cartItem) => {
			return cartItem[modeString] == true && this.isInMenu(cartItem.id);
		});

		let offers = [];
		try {
			let stateOffers = this.state.offers.filter(
				(item) =>
					(this.state.modeOfDelevery == 1
						? item.enable_takeout
						: item.enable_delivery) && item.offerSelected
			);

			let newOffers = this.state.rawOffers.filter((item) =>
				this.state.modeOfDelevery == 1
					? item.enable_takeout
					: item.enable_delivery
			);
			newOffers = getModifiedOfferItems(newOffers);

			stateOffers.map((item) => {
				if (newOffers.some((newOfferItem) => newOfferItem.id == item.id)) {
					offers.push(item);
				} else {
					inputCart = inputCart.filter(
						(cartItem) => cartItem.appliedOfferId !== item.id
					);
				}
			});
			newOffers.map((newOfferItem) => {
				if (!stateOffers.some((item) => item.id == newOfferItem.id)) {
					offers.push(newOfferItem);
				}
			});
		} catch (error) {
			offers = [];
		}
		this.updateOffersList({ cart: inputCart, offers: offers });

		// this.setState({ cart: inputCart });
		// this.dataHandler.setCart(this.state.key_name, inputCart);
	}

	setDeleveryMode = (timeObj = this.state.time, is24Format = false) => {
		let delChange = true;
		let addressParams = { ...this.state.address };

		let shopping_date = this.state.shopping_date;
		let time = is24Format == false ? toTime24(this.state.time) : timeObj;

		let shopping_time_string = this.state.shopping_date + " " + time;
		if (this.state.cart.length > 0) {
			confirmAction(
				"Warning",
				"Some items you have in cart is not available for this mode, Do you want to remove all those items? cancel if not.",
				"Continue"
			).then((userResponse) => {
				if (userResponse.isConfirmed == true) {
					delChange = true;
					this.dataHandler.setShoppingTime(
						this.state.key_name,
						shopping_time_string
					);
					this.dataHandler.setAddress(
						this.state.key_name,
						this.state.address
					);

					this.dataHandler.setShoppingAddress(
						this.state.key_name,
						addressParams
					);
					this.dataHandler.setDeleveryMode(
						this.state.key_name,
						this.state.del_mode
					);
					this.setState({ modeOfDelevery: this.state.del_mode });
					this.reloadData();
				}
			});
		} else {
			this.dataHandler.setDeleveryMode(
				this.state.key_name,
				this.state.del_mode
			);
			this.dataHandler.setAddress(this.state.key_name, this.state.address);
			this.dataHandler.setShoppingTime(
				this.state.key_name,
				shopping_time_string
			);
			this.dataHandler.setShoppingAddress(this.state.key_name, addressParams);
			this.setState({ modeOfDelevery: this.state.del_mode });
			this.reloadData();
		}
		this.enableModeChoosePopup(false);
	};
	hideMenuProfile = () => this.setState({ showMenuProfile: false });
	hideAddressModal = () => this.setState({ showAddressModal: false });

	updateOffersList = ({ cart, offers }) => {
		let modifiedOffers = [];
		try {
			let cartSubTotal = cart.reduce(
				(total, item) => (total += +item.price * item.quantity),
				0
			);
			cartSubTotal = cartSubTotal.toFixed(2);
			modifiedOffers = getCartOfferList({
				offers: offers ?? this.state.offers,
				cartTotalItemsAmount: cartSubTotal,
			});
		} catch (error) {
			modifiedOffers = [];
		}
		this.updateOffers(modifiedOffers, cart);
	};

	setQty = (cartItem, idx) => {
		let inputCart = [...this.state.cart];
		if (cartItem.quantity == 0) {
			inputCart.splice(idx, 1);
		} else {
			inputCart[idx].quantity = cartItem.quantity;
			inputCart[idx].discount = cartItem.discount;
		}

		this.updateOffersList({ cart: inputCart });
	};

	setCustomaize = (cartItem) => {
		let inputCart = [...this.state.cart];

		let total = 0;
		if (cartItem.upCellItems) {
			total = cartItem.upCellItems.reduce(function (previous, current) {
				return previous + +(current.item_price * current.quantity);
			}, 0);
		}
		let answerTotal = 0;
		if (cartItem.customAnswers) {
			answerTotal = cartItem.customAnswers.reduce(function (
				previous,
				current
			) {
				return previous + +current.item_price;
			},
			0);
			total = total + answerTotal;
		}
		let customizationTotal = total.toFixed(2);
		let itemPrice = cartItem.price;
		cartItem.price = (Number(itemPrice) + Number(customizationTotal)).toFixed(
			2
		);
		cartItem.itemPrice = cartItem.itemPrice || itemPrice;
		// cartItem.price = Number(total).toFixed(2);
		inputCart.push(cartItem);
		this.setState(
			{ itemSelected: undefined, showMenuProfile: false }
			// () => {
			// 	this.dataHandler.setCart(this.state.key_name, inputCart);
			// }
		);
		this.updateOffersList({ cart: inputCart });
	};

	comparePriority(a, b) {
		if (a.priority < b.priority) {
			return -1;
		}
		if (a.priority > b.priority) {
			return 1;
		}
		return 0;
	}
	reloadFoodData = (id) => {
		let name = this.props.pathName;
		let inputParams = { name: name, id: id };
		this.dataHandler.getFoodProfile(inputParams).then((response) => {
			let foodObj = response.data.data;
			console.log("FoodData", foodObj);
			this.setState({ showMenuProfile: true, itemSelected: foodObj });
		});
	};

	updateOffers = (offers, cart) => {
		let inputCart = [...cart];
		let modifiedOffers = [...offers];
		// offfer removal part
		// let removedOffers = [];
		try {
			offers.map((item) => {
				if (!item.offerReadyToSelect && item.offerSelected) {
					inputCart = inputCart.filter(
						(cartItem) => cartItem.appliedOfferId !== item.id
					);

					modifiedOffers = modifiedOffers.map((offerItem) => {
						if (item.id === offerItem.id) {
							return { ...offerItem, offerSelected: false };
						}
						return offerItem;
					});
				} else if (
					item.offerSelected &&
					!inputCart.some(
						(cartItem) => cartItem.appliedOfferId === item.id
					)
				) {
					modifiedOffers = modifiedOffers.map((offerItem) => {
						if (item.id === offerItem.id) {
							return { ...offerItem, offerSelected: false };
						}
						return offerItem;
					});
				}
			});
		} catch (error) {}

		this.setState({ cart: inputCart }, () => this.applyRuleBasedOffers());
		// this.dataHandler.setCart(this.state.key_name, inputCart);

		this.setState({ offers: modifiedOffers });
		this.dataHandler.setOffers(this.state.key_name, modifiedOffers);
	};

	setOffers = (offers) => {
		if (this.state.offers?.length <= 0) {
			const modifiedOffers = getModifiedOfferItems(offers);
			this.setState({ offers: modifiedOffers });
			this.dataHandler.setOffers(this.state.key_name, modifiedOffers);
		}
	};

	setOffers = (offers) => {
		if (this.state.offers?.length <= 0) {
			const modifiedOffers = getModifiedOfferItems(offers);
			this.setState({ offers: modifiedOffers });
			this.dataHandler.setOffers(this.state.key_name, modifiedOffers);
		}
	};

	//get restaurant-gallery-restaurant-profile
	/*
    fetch elements from server and reload data elements
    */
	reloadData = () => {
		let name = this.props.pathName;
		let time_obj = this.dataHandler.getShopingTime(name);
		let delivery_mode = this.dataHandler.getDeleveryMode(name);
		if (!delivery_mode == 1 && !delivery_mode == 0) {
			delivery_mode = this.state.del_mode;
		}
		let inputParams = { name: name };
		if (time_obj != false) {
			let day_string = getRestaurantTimeSlot(time_obj);
			//let d = new Date();
			//let n = d.getDay() + 1;

			inputParams["day"] = day_string;
			inputParams["order_mode"] = delivery_mode == 1 ? 1 : 0;
		}
		inputParams["current_day"] = getRestaurantTimeSlot(false);

		this.dataHandler.getProfile(inputParams).then((response) => {
			let currentData = response.data.data;
			let availableDaysForTakeout = currentData.profile.available_days.filter(
				(item) => item.order_mode === 1
			);
			let availableDaysForDelivery =
				currentData.profile.available_days.filter(
					(item) => item.order_mode === 0
				);
			availableDaysForTakeout = dayArrayToDic(availableDaysForTakeout);
			availableDaysForDelivery = dayArrayToDic(availableDaysForDelivery);
			currentData.profile["availableDaysForTakeout"] =
				availableDaysForTakeout;
			currentData.profile["availableDaysForDelivery"] =
				availableDaysForDelivery;
			currentData.profile.available_days =
				delivery_mode == 1
					? availableDaysForTakeout
					: availableDaysForDelivery;
			// currentData.profile.available_days=dayArrayToDic(currentData.profile.available_days)
			let AVD = { ...currentData.profile.available_days };
			console.log("Availble days -Restuarent", AVD);
			let profile = currentData.profile;
			this.profile = profile;
			if (
				profile.close_restaurent == false &&
				(profile.disable_takeout == false ||
					profile.disable_delivery == false)
			) {
				// 7start
				let deliveryCharge = 0.0;
				let deleveryFree = 0.0;
				if (profile.enable_draw_delivery_zone) {
					let zone = this.dataHandler.getZone(this.state.key_name);
					if (zone) {
						deliveryCharge = zone.delivery_charge;
						deleveryFree = zone.delivery_free_min_order_value;
						profile["delevery_enabling_charge"] =
							zone.delivery_enabling_order_value;
						profile["enable_delevery_free"] = zone.enable_delivery_free;
					}
				} else {
					let distance = this.dataHandler.getDistance(
						this.state.key_name
					);
					console.log("fetechd distance", distance);
					deliveryCharge = profile.delevery_charge;
					deleveryFree = profile.delevery_free;
					if (distance) {
						var num_distance = Number(distance);
						console.log("fetchd distance", num_distance);

						if (
							num_distance > profile.minim_delevery &&
							num_distance <= profile.maxim_delevery
						) {
							console.log("maxim zone");
							deliveryCharge = profile.max_delevery_charge;
							deleveryFree = profile.max_delevery_free;
						}
					}
				}
				let day = currentData.day;
				let taxEnabled = profile.tax_enabled;

				let categories = currentData.categories;
				let coupens = currentData.coupens;
				// let offerRules =
				// 	this.dataHandler.getOfferRules(this.state.key_name) || [];
				let offerRules = currentData.offer_rules;
				// console.log(offerRules);
				if (offerRules?.length > 0) {
					offerRules = offerRules.filter((rule) =>
						delivery_mode == 1 ? rule.is_takeout : rule.is_delivery
					);
					// this.dataHandler.setOfferRules(this.state.key_name, offerRules);
				}
				this.dataHandler.setOfferRules(this.state.key_name, offerRules);

				// if (!offerRules?.length) {
				// 	offerRules = currentData.offer_rules || [];
				// 	this.dataHandler.setOfferRules(this.state.key_name, offerRules);
				// }

				let menu = currentData.menu;
				this.dataHandler.storeValue({
					name: this.state.key_name + "profile_name",
					value: name,
				});

				// let currentOffers =
				// 	delivery_mode == 1
				// 		? currentData.offers.filter((item) => item.enable_takeout)
				// 		: currentData.offers.filter((item) => item.enable_delivery);

				// this.setOffers(currentOffers);
				this.setState({ rawOffers: currentData.offers });

				this.dataHandler.setRestaurantProfile(this.state.key_name, {
					profile: profile,
					menu: menu,
					categories: categories,
					coupens: coupens,
				});
				this.dataHandler.storeValue({
					name: this.state.key_name + "restaurant",
					value: {
						profile: profile,
						menu: menu,
						categories: categories,
						coupens: coupens,
					},
				});

				this.setState({ openingStatus: "closed" });

				this.setState(
					{
						profile: profile,
						menu: menu,
						categories: categories,
						day: day,
						rest_location: { lat: profile.lat, lng: profile.lng },
						paymentTerms: {
							taxEnabled: taxEnabled,
							deliveryFree: deleveryFree,
							deliveryCharge: deliveryCharge,
						},
						offerRules: offerRules,
						currentlyTakeoutAvailable:
							currentData.currently_takeout_available,
						currentlyDeliveryAvailable:
							currentData.currently_delivery_available,
					},
					() => {
						console.log("cat_recived", this.state.categories);
						this.setNeededCategories();
						this.reloadCartByMode();
					}
				);
			} else {
				var item = {
					popupMessage: profile.message_pop_up
						? profile.message_pop_up
						: "Restaurant is temporarily closed",
				};
				closingPopup(item);
				this.setState({
					closeStatus: true,
					profile: profile,
				});
			}
		});
	};

	getExtraChargeTotal = (amount) => {
		if (
			this.state.profile.extra_payments &&
			this.state.profile.extra_payments.length > 0
		) {
			let extraCharges = this.state.profile.extra_payments.map((extra) => {
				if (
					(this.state.modeOfDelevery == 0 &&
						extra.enable_delivery == true) ||
					(this.state.modeOfDelevery == 1 && extra.enable_takeout == true)
				)
					return {
						name: extra.title,
						value: extra.value,
						enable_percentage: extra.enable_percentage,
						valueAmount:
							extra.enable_percentage == true
								? ((extra.value * amount) / 100).toFixed(2)
								: extra.value,
					};
			});
			return extraCharges;
		} else {
			return [];
		}
	};
	getTaxTotal = (amount) => {
		if (this.state.profile.appliicable_tax) {
			let applicableTax = this.state.profile.appliicable_tax;
			let taxGroup = applicableTax.title;
			let taxArray = applicableTax.added_taxes.map((taxSplit) => {
				return {
					name: taxSplit.title,
					percentage: taxSplit.tax_precentage,
					amount: ((taxSplit.tax_precentage * amount) / 100).toFixed(2),
				};
			});
			return taxArray;
		} else {
			return [];
		}
	};
	getTaxGroupTotal = (amount) => {
		const { appliicable_tax, delivery_tax_enabled, delivery_appliicable_tax } =
			this.state.profile;

		if (!appliicable_tax) {
			return [];
		}

		const taxGroup = appliicable_tax.title;
		let taxableAmount = 0;

		const taxPercentage = appliicable_tax.added_taxes.reduce(
			(total, taxSplit) => total + Number(taxSplit.tax_precentage),
			0
		);

		if (delivery_tax_enabled) {
			const deliveryAmount = Number(this.getDeliveryTotal());
			amount = Number(amount) - deliveryAmount;
			const deliveryTaxPercentage =
				delivery_appliicable_tax.added_taxes.reduce(
					(total, taxSplit) => total + Number(taxSplit.tax_precentage),
					0
				);
			taxableAmount += Number(
				((deliveryTaxPercentage * deliveryAmount) / 100).toFixed(2)
			);
		}
		taxableAmount += Number(((taxPercentage * amount) / 100).toFixed(2));
		const result = {
			name: taxGroup,
			percentage: taxPercentage,
			amount: taxableAmount,
		};
		return result;
	};
	getPayableTotal = () => {
		let payAbleTotal =
			Number(this.getGrandTotal()) + Number(this.state.login_details["tip"]);
		return payAbleTotal.toFixed(2);
	};
	getGrandTotal = () => {
		let grandTotal = 0;
		grandTotal = this.getCartTotal();
		if (this.state.profile.tax_enabled) {
			let taxes = this.getTaxGroupTotal(grandTotal);
			let totalTax = taxes.amount;
			grandTotal = Number(grandTotal) + Number(totalTax);
		}
		return Number(grandTotal).toFixed(2);
	};

	getCartSubTotal = () => {
		let cartSubTotal = this.state.cart.reduce(
			(total, item) =>
				(total +=
					+item.price * item.quantity -
					parseFloat(item.totalOfferDiscount || 0)),
			0
		);
		cartSubTotal = cartSubTotal.toFixed(2);
		return cartSubTotal;
	};
	getDiscountTotal = () => {
		let discountTotal = this.state.cart.reduce(
			(total, item) =>
				(total +=
					Number((item.price * item.discount) / 100) * item.quantity),
			0
		);
		discountTotal = Number(discountTotal).toFixed(2);
		return discountTotal;
	};

	getDeliveryTotal = () => {
		if (this.state.modeOfDelevery == 1) {
			return 0;
		}
		let TotalAmount = Number(this.getCartSubTotal() - this.getDiscountTotal());
		let deliveryCharge =
			TotalAmount == 0 ||
			TotalAmount > Number(this.state.paymentTerms["deliveryFree"])
				? 0
				: this.state.paymentTerms["deliveryCharge"];
		console.log("delevery" + this.state.paymentTerms["deliveryCharge"]);
		return Number(deliveryCharge).toFixed(2);
	};
	getCartTotal = () => {
		let cartTotal =
			Number(this.getCartSubTotal()) +
			Number(this.getDeliveryTotal()) -
			Number(this.getDiscountTotal());
		return cartTotal.toFixed(2);
	};
	incrementItem = (cartItem, idx) => {
		if (cartItem.quantity <= this.state.max) {
			this.setQty(
				{
					id: cartItem.id,
					quantity: cartItem.quantity + 1,
					title: cartItem.title,
					price: cartItem.price,
					discount: cartItem.discount,
					is_delivery_enabled: cartItem.is_delivery_enabled,
					is_takeout_enabled: cartItem.is_takeout_enabled,
				},
				idx
			);
		}
	};
	decreaseItem = (cartItem, idx) => {
		if (cartItem.quantity >= this.state.min) {
			this.setQty(
				{
					id: cartItem.id,
					quantity: cartItem.quantity - 1,
					discount: cartItem.discount,
					price: cartItem.price,
					title: cartItem.title,
					is_delivery_enabled: cartItem.is_delivery_enabled,
					is_takeout_enabled: cartItem.is_takeout_enabled,
				},
				idx
			);
		}
	};

	searchKeywordHandler = (event) => {
		let searchingKeyword = "";
		if (event.target.type == "text") {
			searchingKeyword = event.target.value;
			if (searchingKeyword.length !== 0) {
				searchingKeyword = event.target.value.toLowerCase();
			}
		}
		this.setState({ searchText: searchingKeyword });
	};
	isInMenu(foodId) {
		if (!this.state.menu) {
			return false;
		}
		const DELETE = 1;
		const LIVE = 0;
		let status = false;
		const found = this.state.menu.find((element) => element.item === foodId);
		if (typeof found != "undefined") {
			status = found.delete_status == LIVE;
		}

		return status;
	}

	disableEmptyItem = () => {
		this.setState({ emptyItem: false });
	};
	setNeededCategories = () => {
		let selectedCategories = [];
		if (typeof this.state.categories != "undefined") {
			this.state.categories.map((catObj) => {
				if (typeof catObj != "undefined") {
					catObj.foods_displayed_in_menu =
						catObj.foods_displayed_in_menu.filter((foodObj) =>
							this.isInMenu(foodObj.id)
						);

					if (catObj.foods_displayed_in_menu.length > 0) {
						selectedCategories.push(catObj);
					}
				}
			});
		}
		if (selectedCategories.length == 0) {
			this.setState({ emptyItem: true }, () => {
				this.disableDeleveryMode();
			});
		} else {
			let offerRules = [...this.state.offerRules];
			try {
				offerRules =
					filterOutOfferRules(
						offerRules,
						selectedCategories,
						this.deliveryModes[this.state.modeOfDelevery]
					) || [];
			} catch (e) {
				console.log("Error", e);
			}
			this.dataHandler.setOfferRules(this.state.key_name, offerRules);
			selectedCategories = selectedCategories.sort(this.comparePriority);

			this.setState(
				{
					emptyItem: false,
					selectedCategories: selectedCategories,
					categoryFoodItemMap: createCategoryMap(selectedCategories),
					offerRules: offerRules,
				},
				this.props.setCategories(selectedCategories)
			);
		}
	};

	componentDidMount() {
		window.addEventListener("scroll", this.listenToScroll);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.listenToScroll);
	}

	listenToScroll = (e) => {
		console.log("The log e", document.documentElement.scrollTop);
		const winScroll =
			document.body.scrollTop || document.documentElement.scrollTop;

		const height =
			document.documentElement.scrollHeight -
			document.documentElement.clientHeight;
		console.log("the height is", height);
		const scrolled = (winScroll / height) * 100;
		if (scrolled >= 14) {
			this.setState({
				panelClass: "PopupPanel",
				panelCategoryClass: "PopupPanelCategory",
			});
		} else {
			this.setState({
				panelClass: "",
				panelCategoryClass: "",
			});
		}
		console.log("Scrolled", scrolled);
	};

	dismissOfferNotifications = (ruleToDismiss) => {
		let offerRulesCopy = [...this.state.offerRules];
		offerRulesCopy.forEach((rule) => {
			if (rule.showNotification && ruleToDismiss.id === rule.id) {
				rule.notification_trigger = false;
				rule.showNotification = false;
			}
		});
		this.setState({ offerRules: offerRulesCopy });
		this.dataHandler.setOfferRules(this.state.key_name, offerRulesCopy);
	};

	offerNotificationOnClick = (rule) => {
		let category_id;
		if (
			rule.destinations[0].destination_type === "category" &&
			rule.destinations[0].destination_categories?.length > 0
		) {
			category_id = rule.destinations[0].destination_categories[0];
		} else if (
			rule.destinations[0].destination_type === "item" &&
			rule.destinations[0].destination_items?.length > 0
		) {
			let itemId = rule.destinations[0].destination_items[0];
			category_id = this.state.categoryFoodItemMap.get(itemId);
		}
		if (category_id) {
			this.setState(
				{
					activeCategory: category_id,
					searchText: "",
				},
				() => {
					try {
						window.scroll("catObj" + category_id);
					} catch (error) {
						console.error("An error occurred while scrolling:", error);
						// Optionally, you can handle the error further, e.g., show a user-friendly message
					}
				}
			);
		}
		this.dismissOfferNotifications(rule);
	};
	render() {
		let offerRules = [];
		if (this.state.offerRules?.length > 0) {
			offerRules = this.state.offerRules.filter(
				(rule) => rule.showNotification && rule.message
			);
		}
		return (
			<>
				<Seo
					title={
						this.profile.tab_title
							? this.profile.tab_title
							: "iOrders Online"
					}
					description={
						this.profile.description
							? this.profile.description
							: "iOrders Online"
					}
					keywords={
						this.profile.meta_tags
							? this.profile.meta_tags
							: "iOrders Online,Online Food Ordering, Food Online"
					}
				></Seo>

				<DeliveryModal
					emptyItem={this.state.emptyItem}
					disableEmptyItem={this.disableEmptyItem}
					profile={this.state.profile}
					del_mode={this.state.del_mode}
					setDeleveryMode={this.setDeleveryMode}
					setShoppingAddress={this.setShoppingAddress}
					setShoppingDate={this.setShoppingDate}
					setShoppingTime={this.setShoppingTime}
					setCurrentDeliveryMode={this.setCurrentDeliveryMode}
					changeDeliveryMode={this.changeDeliveryMode}
					addressEror={this.state.addressEror}
					time={this.state.time}
					address={this.state.address}
					setFuture={this.setFuture}
					categories={this.state.categories}
					shopping_date={this.state.shopping_date}
					show={this.state.modeChooseEnabled}
					currentlyTakeoutAvailable={this.state.currentlyTakeoutAvailable}
					currentlyDeliveryAvailable={
						this.state.currentlyDeliveryAvailable
					}
				></DeliveryModal>

				<ViewMenuDiscriptionModel
					decreaseItem={this.decreaseItem}
					incrementItem={this.incrementItem}
					getQty={this.getQty}
					show={
						this.state.showMenuProfile &&
						!this.state.itemSelected.is_combo
					}
					onHide={this.hideMenuProfile}
					itemSelected={this.state.itemSelected}
					setCustomaize={this.setCustomaize}
					default_food_item_image={
						this.state.profile.default_food_item_image
					}
				/>
				{this.state.showMenuProfile && this.state.itemSelected.is_combo && (
					<BundleItemSelectionModal
						showModal={true}
						onHide={this.hideMenuProfile}
						itemSelected={this.state.itemSelected}
						default_food_item_image={
							this.state.profile.default_food_item_image
						}
						wholeFoodItemList={
							this.state.categories
								? this.state.categories.flatMap(
										(catObj) => catObj.foods_displayed_in_menu
								  )
								: []
						}
						decreaseItem={this.decreaseItem}
						incrementItem={this.incrementItem}
						setCustomaize={this.setCustomaize}
						{...this.props}
					/>
				)}
				<section className="restaurant-detailed-banner">
					<div
						className="text-center"
						style={{
							...(!this.state?.profile?.banner
								? { height: 300 }
								: {}),
						}}
					>
						<Image
							fluid
							className="cover"
							src={window.image_path + this.state.profile.banner}
						/>
					</div>
					<div className="restaurant-detailed-header">
						<Container>
							<Row className="d-flex align-items-end">
								<Col md={8}>
									<div className="restaurant-detailed-header-left">
										{typeof (
											this.state.profile.logo != "undefined"
										) && (
											<Image
												fluid
												className="mr-3 float-left"
												alt=""
												src={
													window.image_path +
													this.state.profile.logo
												}
											/>
										)}

										<h2 className="text-white">
											{this.state.profile.restaurant_name}
										</h2>
										<p className="text-white mb-1">
											<Icofont icon="location-pin" />
											{typeof (
												this.state.profile.address !=
												"undefined"
											) && this.state.profile.address}
											<div className="restaurant-detailed-header-right text-right"></div>
										</p>
										<p className="text-white mb-0">
											<Icofont icon="food-cart" />
											{this.state.profile.phone}
											<br></br>
											{typeof this.state.profile
												.tagged_categories != "undefined" &&
												this.state.profile.tagged_categories.map(
													(tagedObj) => {
														//	if(tagedObj){
														return (
															<Link
																className="border-btn text-success mr-2"
																to="#"
															>
																<Icofont icon="check-circled" />{" "}
																{tagedObj}
															</Link>
														);
														//	}
													}
												)}
										</p>
									</div>
								</Col>
								<Col md={4}>
									<div className="restaurant-detailed-header-right text-right">
										<Button
											variant="success"
											type="button"
											style={{ height: "auto" }}
										>
											{this.state.del_mode == 0
												? "Delivery  "
												: "Takeout  "}
											<Icofont icon="clock-time" />{" "}
											{this.state.modeOfDelevery == 0
												? typeof (
														this.state.profile
															.preparation_time_for_delivery !=
														"undefined"
												  ) &&
												  this.state.profile
														.preparation_time_for_delivery
												: typeof (
														this.state.profile
															.avg_preparation_time !=
														"undefined"
												  ) &&
												  this.state.profile
														.avg_preparation_time}
										</Button>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</section>

				<Tab.Container defaultActiveKey="first">
					<section className="offer-dedicated-nav bg-white border-top-0 shadow-sm">
						<Container>
							<Row>
								<Col md={12}>
									<span className="restaurant-detailed-action-btn float-right">
										<button
											onClick={() => {
												this.disableDeleveryMode();
											}}
											class=""
											className="btn btn-danger btn-block text-white text-center btn-sm "
										>
											<i class="icofont-food-cart"></i>{" "}
											{this.state.del_mode == 0
												? "Change Order Type"
												: "Change Order Type"}
										</button>
									</span>
									<Nav id="pills-tab">
										<Nav.Item>
											<Nav.Link
												eventKey="first"
												onClick={() => {
													this.setState(
														{ catview: false },
														() => {
															window.scroll(
																"catstart"
															);
														}
													);
													this.setState({
														catview: true,
														gallerylength: 5,
													});
												}}
											>
												Order Online
											</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link
												eventKey="second"
												onClick={() => {
													this.setState({
														catview: false,
														gallerylength: 8,
													});
												}}
											>
												Gallery
											</Nav.Link>
										</Nav.Item>
										<Nav.Item>
											<Nav.Link
												eventKey="third"
												onClick={() => {
													this.setState({
														catview: false,
														gallerylength: 8,
													});
												}}
											>
												{" "}
												Info
											</Nav.Link>
										</Nav.Item>
									</Nav>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="offer-dedicated-body pt-2 pb-2 mt-4 mb-4">
						<Container>
							<Row>
								{this.state.catview == true && (
									<Col md={3} className="content-desktop">
										<div className="PopupPanelCategory">
											<ul className="nav flex-column border-0 mt-4">
												{/* <b className="mb-4 pl-2 text-dark"><Icofont icon="lunch" className="icofont-2x text-success mr-1"/>Categories</b>*/}
												{typeof this.state
													.selectedCategories !=
													"undefined" &&
													this.state.selectedCategories.map(
														(catObj) => {
															let hasFoodItems = true;
															try {
																hasFoodItems =
																	catObj.foods_displayed_in_menu.some(
																		(
																			foodObj
																		) => {
																			let modeString =
																				this
																					.deliveryModes[
																					this
																						.state
																						.modeOfDelevery
																				];
																			return (
																				foodObj[
																					modeString
																				] ==
																					true &&
																				(this
																					.state
																					.searchText
																					.length ===
																					0 ||
																					foodObj.title
																						.toLowerCase()
																						.indexOf(
																							this.state.searchText.toLowerCase()
																						) !==
																						-1)
																			);
																		}
																	);
															} catch (e) {
																hasFoodItems = true;
															}
															return hasFoodItems ? (
																<li className="nav-item">
																	<div className=" pt-2 pb-2 pl-2">
																		<a
																			className="text-dark cursorPointer"
																			onClick={() => {
																				this.setState(
																					{
																						activeCategory:
																							catObj.id,
																					},
																					() => {
																						window.scroll(
																							"catObj" +
																								catObj.id
																						);
																					}
																				);
																			}}
																			id={
																				catObj.id
																			}
																		>
																			<span className="pl-2">
																				{this
																					.state
																					.activeCategory ==
																				catObj.id ? (
																					<b>
																						{
																							catObj.title
																						}
																					</b>
																				) : (
																					catObj.title
																				)}

																				{this
																					.state
																					.activeCategory ==
																					catObj.id && (
																					<span className="arrow"></span>
																				)}
																			</span>
																		</a>
																	</div>
																</li>
															) : (
																<></>
															);
														}
													)}
											</ul>
										</div>
									</Col>
								)}
								<Col md={this.state.gallerylength}>
									<div className="offer-dedicated-body-left">
										<Tab.Content className="h-100">
											<Tab.Pane eventKey="first">
												{this.state.selectedCategories && (
													<div id={"catHead" + 0}></div>
												)}
												<h5 className="mb-4"></h5>
												<Form className="explore-outlets-search mb-4">
													<InputGroup>
														<Form.Control
															type="text"
															onChange={
																this
																	.searchKeywordHandler
															}
															placeholder="Search..."
															className="addresslocat"
														/>
														<InputGroup.Append>
															<Button
																type="button"
																variant="link"
															>
																<Icofont icon="search" />
															</Button>
														</InputGroup.Append>
													</InputGroup>
												</Form>

												{this.state.selectedCategories && (
													<span className="cathide ">
														{/* <ItemsCarousel />*/}

														<CategoriesCarousel
															categories={
																this.state
																	.selectedCategories
															}
														/>
													</span>
												)}

												<div id="catstart"></div>

												{typeof this.state.categories !=
													"undefined" &&
													this.state.selectedCategories.map(
														(catObj, catidx) => {
															// Check if there are any food items to display for this category
															let hasFoodItems = true;
															try {
																hasFoodItems =
																	catObj.foods_displayed_in_menu.some(
																		(
																			foodObj
																		) => {
																			let modeString =
																				this
																					.deliveryModes[
																					this
																						.state
																						.modeOfDelevery
																				];
																			return (
																				foodObj[
																					modeString
																				] ==
																					true &&
																				(this
																					.state
																					.searchText
																					.length ===
																					0 ||
																					foodObj.title
																						.toLowerCase()
																						.indexOf(
																							this.state.searchText.toLowerCase()
																						) !==
																						-1)
																			);
																		}
																	);
															} catch (e) {
																hasFoodItems = true;
															}
															// Only render the category if there are food items to display
															if (hasFoodItems) {
																return (
																	<Row>
																		<h5
																			className="mb-4 mt-3 col-md-12"
																			id={
																				"catObj" +
																				catObj.id
																			}
																		>
																			{
																				catObj.title
																			}
																		</h5>
																		<Col
																			md={12}
																		>
																			<div className="">
																				{catObj.foods_displayed_in_menu.map(
																					(
																						foodObj,
																						idx
																					) => {
																						let modeString =
																							this
																								.deliveryModes[
																								this
																									.state
																									.modeOfDelevery
																							];

																						if (
																							foodObj[
																								modeString
																							] ==
																							true
																						) {
																							let image =
																								"img/empty_image.jpeg";
																							let enable_image_display =
																								foodObj.enable_image_display;
																							if (
																								enable_image_display
																							) {
																								if (
																									foodObj.image
																								) {
																									image =
																										window.image_path +
																										foodObj.image;
																								} else if (
																									this
																										.state
																										.profile
																										.default_food_item_image
																								) {
																									image =
																										window.image_path +
																										this
																											.state
																											.profile
																											.default_food_item_image;
																								}
																							}

																							// let image = "img/list/1.png";
																							// image = foodObj.image
																							//   ? window.image_path  + foodObj.image
																							//   : image;
																							if (
																								this
																									.state
																									.searchText
																									.length ===
																									0 ||
																								foodObj.title
																									.toLowerCase()
																									.indexOf(
																										this.state.searchText?.toLowerCase()
																									) !=
																									-1
																							) {
																								return (
																									<div>
																										{catObj
																											.foods_displayed_in_menu[
																											catObj
																												.foods_displayed_in_menu
																												.length -
																												1
																										]
																											.id ==
																											foodObj.id && (
																											<div
																												id={
																													"catHead" +
																													(catidx +
																														1)
																												}
																											></div>
																										)}
																										<span
																											className="pointer-link"
																											onClick={() =>
																												this.reloadFoodData(
																													foodObj.id
																												)
																											}
																										>
																											<QuickBite
																												id={
																													foodObj.id
																												}
																												itemClass="menu-list"
																												title={
																													foodObj.title
																												}
																												image={
																													image
																												}
																												price={
																													foodObj.price
																												}
																												description={
																													foodObj.description
																												}
																												showBadge={
																													foodObj.discount >
																													0
																												}
																												badgeText="BEST SELLER"
																												badgeText="Customaize"
																												priceUnit="$"
																												getValue={
																													this
																														.setQty
																												}
																												discount={
																													foodObj.discount
																												}
																												item={{
																													id: foodObj.id,
																													discount:
																														foodObj.discount,
																													title: foodObj.title,
																													price: foodObj.price,
																													is_delivery_enabled:
																														foodObj.is_delivery_enabled,
																													is_takeout_enabled:
																														foodObj.is_takeout_enabled,
																													is_vegan:
																														foodObj.is_vegan,
																													is_gluten_free:
																														foodObj.is_gluten_free,
																													is_vegetarian:
																														foodObj.is_vegetarian,
																												}}
																												getValue={
																													this
																														.setQty
																												}
																												decreaseItem={
																													this
																														.decreaseItem
																												}
																												incrementItem={
																													this
																														.incrementItem
																												}
																												onViewClick={() =>
																													this.setState(
																														{
																															showMenuProfile: true,
																															itemSelected:
																																foodObj,
																														}
																													)
																												}
																												subTitle={
																													foodObj.food_types
																												}
																											/>
																										</span>
																									</div>
																								);
																							}
																						}
																					}
																				)}
																			</div>
																		</Col>
																	</Row>
																);
															} else {
																return <></>;
															}
														}
													)}
											</Tab.Pane>
											<Tab.Pane
												eventKey="second"
												className="mt-4"
											>
												<div className="position-relative">
													<GalleryCarousel
														images={
															this.state.profile
																.images
														}
													/>
												</div>
											</Tab.Pane>
											<RestaurantTab
												profile={this.state.profile}
												day={this.state.day}
												status={this.state.openingStatus}
											></RestaurantTab>
										</Tab.Content>
									</div>
								</Col>

								{this.state.catview == true && (
									<Col md={4} className="mt-4">
										<div className="PopupPanel">
											{this.state.cart.length == 0 && (
												<div>
													<Image
														className="float-center"
														alt=""
														src="/img/emptycart.jpg"
														width="100%"
														height="100%"
													/>
												</div>
											)}

											{this.state.cart.length > 0 && (
												<div
													className="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item"
													id="yourAnchorTag"
												>
													<div className="content_cart">
														<h5 className="mb-1 ">
															{
																this.orderString[
																	this.state
																		.modeOfDelevery
																]
															}
														</h5>
														<p className="mb-4 ">
															{this.state.cart.length}{" "}
															Items
														</p>
														<div className="bg-white rounded shadow-sm mb-2 itemPanel">
															{this.state.cart
																.map(
																	(
																		item,
																		index
																	) => ({
																		...item,
																		originalIndex:
																			index,
																	})
																)
																.sort((a, b) =>
																	a.isFree ===
																	b.isFree
																		? 0
																		: a.isFree
																		? 1
																		: -1
																)
																.map((itemObj) => (
																	<CheckoutItem
																		item={
																			itemObj
																		}
																		title={
																			itemObj.title
																		}
																		key_name={
																			this
																				.state
																				.key_name
																		}
																		price={
																			itemObj.price
																		}
																		priceUnit="$"
																		id={
																			itemObj.id
																		}
																		cartIndex={
																			itemObj.originalIndex
																		}
																		quantity={
																			itemObj.quantity
																		}
																		show={true}
																		minValue={0}
																		maxValue={
																			50
																		}
																		decreaseItem={
																			this
																				.decreaseItem
																		}
																		incrementItem={
																			this
																				.incrementItem
																		}
																		getValue={
																			this
																				.setQty
																		}
																	/>
																))}
														</div>

														<div className="mb-2 bg-white rounded p-2 clearfix">
															<Image
																fluid
																className="float-left"
																src="/img/wallet-icon.png"
															/>
															<h6 className="font-weight-bold text-right mt-3">
																Subtotal :{" "}
																<span>
																	{" "}
																	$
																	{this.getCartSubTotal()}
																</span>
															</h6>
															{/* 
                          {this.getDeliveryTotal() > 0 && (
                            <p className="seven-color mb-1 text-right">
                              Delivery Charge:
                              <span>$ {this.getDeliveryTotal()}</span>
                            </p>
                          )}
                          {this.getDiscountTotal() > 0 && (
                            <p className="seven-color mb-1 text-right">
                              Applied Discount:
                              <span>${this.getDiscountTotal()}</span>
                            </p>
                          )}
                          {this.state.profile.tax_enabled == true &&
                            this.getTaxGroupTotal(this.getCartTotal()).amount >
                              0 && [
                              <h6 className="font-weight-bold text-right mb-2">
                                Taxable amount :{" "}
                                <span> ${this.getCartTotal()}</span>
                              </h6>,

                              <p className="seven-color mb-1 text-right">
                                <h6>Applied Tax</h6>
                              </p>,

                              <p className="seven-color mb-1 text-right">
                                {
                                  this.getTaxGroupTotal(this.getCartTotal())
                                    .name
                                }
                                :
                                <span>
                                  $
                                  {
                                    this.getTaxGroupTotal(this.getCartTotal())
                                      .amount
                                  }
                                </span>
                              </p>,
                            ]}

                          <h6 className="font-weight-bold text-right mb-2">
                            Total : <span> ${this.getGrandTotal()}</span>
                          </h6>
                          */}
															<p className="text-black mb-0 text-right"></p>
														</div>
														{offerRules?.length > 0 && (
															<div
																style={{
																	position:
																		"fixed",
																	bottom: "7%",
																	left: "50%",
																	transform:
																		"translateX(-50%)",
																	width: "90%",
																	background:
																		"transparent",
																}}
															>
																{offerRules.map(
																	(rule) => (
																		<Alert
																			key={
																				rule.id
																			}
																			variant="secondary"
																			className="d-md-none"
																			style={{
																				background:
																					"yellowGreen",
																				color: "#000000",
																				padding:
																					"10px 15px",
																				borderRadius:
																					"8px",
																				boxShadow:
																					"0 2px 10px rgba(0, 0, 0, 0.3)",
																				display:
																					"flex",
																				alignItems:
																					"center",
																				justifyContent:
																					"space-between",
																				cursor: "pointer",
																			}}
																			onClick={() =>
																				this.offerNotificationOnClick(
																					rule
																				)
																			}
																		>
																			<div
																				style={{
																					display:
																						"flex",
																					alignItems:
																						"center",
																					justifyContent:
																						"space-between",
																					width: "100%",
																				}}
																			>
																				<div>
																					<div
																						style={{
																							display:
																								"flex",
																							alignItems:
																								"center",
																						}}
																					>
																						<span
																							role="img"
																							aria-label=""
																							style={{
																								marginRight:
																									"8px",
																							}}
																						>
																							🌟
																						</span>
																						<span>
																							{
																								rule.message
																							}
																						</span>
																					</div>
																				</div>
																				<span
																					className="icofont-close"
																					style={{
																						cursor: "pointer",
																						marginLeft:
																							"15px",
																					}}
																					onClick={() =>
																						this.dismissOfferNotifications(
																							rule
																						)
																					}
																				></span>
																			</div>
																		</Alert>
																	)
																)}
															</div>
														)}
														{offerRules?.length > 0 && (
															<div
																style={{
																	position:
																		"fixed",
																	bottom: "5%",
																	left: "50%",
																	transform:
																		"translateX(-50%)",
																	width: "30%",
																	zIndex: 1000,
																	background:
																		"transparent",
																}}
															>
																{offerRules.map(
																	(rule) => (
																		<Alert
																			key={
																				rule.id
																			}
																			variant="secondary"
																			className="d-none d-md-block"
																			style={{
																				background:
																					"yellowGreen",
																				color: "#000000",
																				padding:
																					"10px 15px",
																				borderRadius:
																					"8px",
																				boxShadow:
																					"0 2px 10px rgba(0, 0, 0, 0.3)",
																				display:
																					"flex",
																				alignItems:
																					"center",
																				justifyContent:
																					"space-between",
																				cursor: "pointer",
																			}}
																			onClick={() =>
																				this.offerNotificationOnClick(
																					rule
																				)
																			}
																		>
																			<div
																				style={{
																					display:
																						"flex",
																					alignItems:
																						"center",
																					justifyContent:
																						"space-between",
																					width: "100%",
																				}}
																			>
																				<div>
																					<div
																						style={{
																							display:
																								"flex",
																							alignItems:
																								"center",
																						}}
																					>
																						<span
																							role="img"
																							aria-label=""
																							style={{
																								marginRight:
																									"8px",
																							}}
																						>
																							🌟
																						</span>
																						<span>
																							{
																								rule.message
																							}
																						</span>
																					</div>
																				</div>
																				<span
																					className="icofont-close"
																					style={{
																						cursor: "pointer",
																						marginLeft:
																							"15px",
																					}}
																					onClick={() =>
																						this.dismissOfferNotifications(
																							rule
																						)
																					}
																				></span>
																			</div>
																		</Alert>
																	)
																)}
															</div>
														)}
														{this.state
															.modeOfDelevery == 0 &&
															this.state.profile
																.enable_delevery_free ==
																true &&
															this.getDeliveryFree() >
																0 && (
																<span
																	className=" backgrd_unreached"
																	style={{
																		height: "auto",
																	}}
																>
																	{/* <span className="imgspan"><Image
                  fluid
                  className="float-left"
                  src="/img/motorbike.png"
                  /></span> */}
																	<span>
																		You are $
																		{this.getDeliveryFree()}{" "}
																		away from
																		free
																		delivery.
																	</span>
																</span>
															)}

														{this.state
															.modeOfDelevery == 0 &&
															this.state.profile
																.enable_delevery_free ==
																true &&
															this.getDeliveryFree() ==
																0 &&
															this.getCartSubTotal() >
																0 && (
																<span
																	className=" backgrd"
																	style={{
																		height: "auto",
																		backgroundColor:
																			"#9bb341",
																	}}
																>
																	{/* <span className="imgspan"><Image
                  fluid
                  className="float-left"
                  src="/img/motorbike.png"
                  /></span> */}
																	<span>
																		Awesome !
																		The delivery
																		is free.
																	</span>
																</span>
															)}

														{this.state
															.modeOfDelevery == 1 &&
															this.state.profile
																.enable_takeout_discount ==
																true &&
															this.getTakeoutDiscount() ==
																0 &&
															this.getCartSubTotal() >
																0 && (
																<div
																	className="backgrd"
																	style={{
																		height: "auto",
																		backgroundColor:
																			"#9bb341",
																	}}
																>
																	{/* <span className="imgspan"><Image
                  fluid
                  className="float-left"
                  src="/img/hot-sale.png"
                  /></span> */}
																	<span>
																		Awesome! You
																		have{" "}
																		{Number(
																			this
																				.state
																				.profile
																				.take_out_discount
																		).toFixed(
																			0
																		)}
																		% discount
																		in this
																		order.
																	</span>
																</div>
															)}

														{this.state
															.modeOfDelevery == 1 &&
															this.state.profile
																.enable_takeout_discount ==
																true &&
															this.getTakeoutDiscount() >
																0 && (
																<div
																	className=" backgrd_unreached"
																	style={{
																		height: "auto",
																	}}
																>
																	{/* <span className="imgspan"><Image
                  fluid
                  className="float-left"
                  src="/img/hot-sale.png"
                  /></span> */}
																	<span>
																		You are $
																		{this.getTakeoutDiscount()}{" "}
																		away from
																		getting{" "}
																		{Number(
																			this
																				.state
																				.profile
																				.take_out_discount
																		).toFixed(
																			0
																		)}
																		% discount.
																	</span>
																</div>
															)}
														<div className="pt-2"></div>
														{this.state.offers
															.filter(
																(item) =>
																	!item.offerReadyToSelect
															)
															.map((item) => (
																<div
																	className=" backgrd_unreached"
																	style={{
																		height: "auto",
																	}}
																>
																	<span>
																		{
																			item.offerRelatedCustomerMessage
																		}
																	</span>
																</div>
															))}
														{this.state.offers
															.filter(
																(item) =>
																	item.offerReadyToSelect
															)
															.map((item) => (
																<div
																	className=" backgrd"
																	style={{
																		height: "auto",
																		backgroundColor:
																			"#9bb341",
																	}}
																>
																	<span>
																		{item.offerRelatedCustomerMessage +
																			" " +
																			"Go to checkout page for offer selection"}
																	</span>
																</div>
															))}
														<div className="pt-2"></div>
														{(!(
															this.state
																.modeOfDelevery == 0
														) ||
															(!(
																this.getCartSubTotal() <
																Number(
																	this.state
																		.profile
																		.delevery_enabling_charge
																)
															) &&
																this.state.profile
																	.delevery_enabling_charge !==
																	undefined)) && (
															<Link
																style={{
																	borderRadius: 8,
																}}
																to={{
																	pathname:
																		"/" +
																		this.state
																			.key_name +
																		"/Checkout",
																	state: {
																		cart: this
																			.state
																			.cart,
																		key_name:
																			this
																				.state
																				.key_name,
																	},
																}}
																className="btn btn-success btn-block btn-lg"
															>
																Checkout
																<Icofont icon="long-arrow-right" />
															</Link>
														)}

														{this.state
															.modeOfDelevery == 0 &&
															this.getCartSubTotal() <
																Number(
																	this.state
																		.profile
																		.delevery_enabling_charge
																) &&
															this.state.profile
																.delevery_enabling_charge !==
																undefined && (
																<span
																	className="error_delivery"
																	style={{
																		height: "auto",
																	}}
																>
																	The minimum
																	order value
																	required for
																	delivery is $
																	{
																		this.state
																			.profile
																			.delevery_enabling_charge
																	}
																	. Add more to
																	checkout
																</span>
															)}
														<div className="pt-2"></div>

														<div className="pt-2"></div>
													</div>{" "}
												</div>
											)}
										</div>
									</Col>
								)}
							</Row>
						</Container>
					</section>
				</Tab.Container>
			</>
		);
	}
}

export default Detail;
